/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type COMMENT_SORT = "CREATED_AT_ASC" | "CREATED_AT_DESC" | "REACTION_DESC" | "REPLIES_DESC" | "%future added value";
export type ConversationModalCommentContainerLocal = {
    readonly moderationQueueSort: COMMENT_SORT | null;
    readonly " $refType": "ConversationModalCommentContainerLocal";
};
export type ConversationModalCommentContainerLocal$data = ConversationModalCommentContainerLocal;
export type ConversationModalCommentContainerLocal$key = {
    readonly " $data"?: ConversationModalCommentContainerLocal$data;
    readonly " $fragmentRefs": FragmentRefs<"ConversationModalCommentContainerLocal">;
};



const node: ReaderFragment = ({
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "ConversationModalCommentContainerLocal",
    "selections": [
        {
            "kind": "ClientExtension",
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "moderationQueueSort",
                    "storageKey": null
                }
            ]
        }
    ],
    "type": "Local",
    "abstractKey": null
} as any);
(node as any).hash = '2c8b28fa024dfcf72ed86b4e1713b708';
export default node;
